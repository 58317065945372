export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    // {
    //   name: 'dashboard',
    //   displayName: 'menu.dashboard',
    //   meta: {
    //     icon: 'vuestic-iconset-dashboard',
    //   },
    // },
    {
      name: 'merchants',
      displayName: 'menu.merchants',
      meta: {
        icon: 'receipt_long',
      },
    },
    {
      name: 'pos_menu',
      displayName: 'menu.posMenu',
      meta: {
        icon: 'money',
      },
      children: [
        {
          name: 'pos',
          displayName: 'menu.pos',
          meta: {
            icon: 'ad_units',
          },
        },
        {
          name: 'pos-contract',
          displayName: 'menu.posContract',
          meta: {
            icon: 'topic',
          },
        },
      ],
    },
    {
      name: 'report',
      displayName: 'menu.report',
      meta: {
        icon: 'insert_chart',
      },
      children: [
        {
          name: 'transactions',
          displayName: 'menu.transactionsReport',
          meta: {
            icon: 'trending_up',
          },
        },
        {
          name: 'merchant-report',
          displayName: 'menu.merchantReport',
          meta: {
            icon: 'fact_check',
          },
        },
      ],
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
